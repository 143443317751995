import React, { useContext, useEffect, useState } from 'react';
import { Button, Header, Icon, Transition, Tab } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { DashboardNavBar } from '../Dashboard/DashboardNavBar/DashboardNavBar';
import './dashboardsettings.css';
import { DashboardGeneral } from './General/DashboardGeneral';

export const DashboardSettings = (props) => {

    const panes = [
        {
            menuItem: 'General',
            render: () => <Tab.Pane attached={false}>
                <DashboardGeneral/>
            </Tab.Pane>,
        }
    ]

    return (
        <div>
            <DashboardNavBar />
            <div className="dashboard-container-content">

                <div className="dashboard-content-box">

                    <div className="simulations-header">
                        <div className="page-header-text">
                            <Header as='h1' disabled>
                                <Link to="/dashboard"><Button className="simulation-back-button" size="massive" icon>
                                    <Icon name='left chevron' />
                                </Button></Link>
                                Settings
                             </Header>
                        </div>
                    </div>
                    <div className="dashboard-settings-box">
                        <Tab className="super-admin-tab-menu" menu={{ secondary: true }} panes={panes} />
                    </div>

                    <div style={{color:"#ccc", fontSize: "1.4em"}} className="simulations-header">
                        version: 0.9.0
                    </div>
                </div>
            </div>


        </div>
        )
}